import React, { useContext } from "react"
import { Context as AuthContext } from '../contexts/AuthContext'
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { FullPageLoader } from '../components'
import liff from '@line/liff';

import { useCurrentAccountingBook } from '../hooks'

const GroupIdRedirect = ({
  page
}) => {
  const { state: authState } = useContext(AuthContext)
  const { group_id } = useParams();

  if (group_id) {
    let redirectPath = `https://liff.line.me/${process.env.REACT_APP_LINE_LIFF_ID}/groups/${group_id}/current_new_payment`
    window.location = redirectPath
  }

  return <FullPageLoader />
}

export default GroupIdRedirect
