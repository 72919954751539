import React, { useState, useEffect } from 'react';
import styles from './UserCheckboxSelectLabel.module.scss'
import { split_into, round } from '../../../utilities/Calculator';
import {
  Svg,
  Drawer,
  Image,
  EditableCheckboxSelect
} from '../../../components'
import {
  useOwersFilterSelect,
} from '../../../hooks'
import {
  userImageUrls
} from '../../../constants'
import {
  createEditableOwerCheckbokLabel,
  createEditableOwerCopiesCheckbokLabel
} from '../../../generators/labelGenerator'

const Label = ({
  setSummaryAmount,
  exponent,
  setOwerAmount,
  setManualOwers,
  fixedAmount,
  users,
  manualOwers,
  getManualOwersAmount,
  selectedObjects,
  selectChanged,
  allocationType,
  setAllocationType,
  handleOpen,
  handleDrawerClosed
}) => {
  const [drawerActive, setDrawerActive] = useState(false)
  const [_selectObjectIds, setSelectObjectIds] = useState(new Set())
  const [_manualOwers, _setManualOwers] = useState(manualOwers)
  const valid = true

  useEffect(() => {
    _setManualOwers(manualOwers)
  }, [manualOwers])

  useEffect(() => {
    if (selectedObjects.length === _selectObjectIds.size) {
      return
    }

    if (selectedObjects) {
      setSelectObjectIds(new Set(selectedObjects.map(o => o.id)))
    } else {
      setSelectObjectIds(new Set(users.map(u => u.id)))
    }
  }, [users, selectedObjects])

  const handleSelectChanged = (objects) => {
    if (allocationType == 0) {
      setSelectObjectIds(new Set(objects.map(obj => obj.id)))
      if (selectChanged) { selectChanged(objects) }
    }
  }

  const handleClicked = () => {
    handleOpen()
    setDrawerActive(!drawerActive)
  }

  const handleClosed = () => {
    handleDrawerClosed()
    setDrawerActive(false)
  }

  let select = <EditableCheckboxSelect
    prefix="payer"
    title="付款者"
    exponent={exponent}
    setSummaryAmount={setSummaryAmount}
    getManualOwersAmount={getManualOwersAmount}
    fixedAmount={fixedAmount}
    setOwerAmount={setOwerAmount}
    setManualOwers={setManualOwers}
    manualOwers={_manualOwers}
    closed={handleClosed}
    objects={users}
    createLabel={createEditableOwerCheckbokLabel}
    createCopiesLabel={createEditableOwerCopiesCheckbokLabel}
    allocationType={allocationType}
    setAllocationType={setAllocationType}
    selectAll={true}
    selected_object_ids={_selectObjectIds}
    changed={handleSelectChanged}
    />


  let selected

  if (allocationType === 1) {
    selected = _manualOwers.value.filter(o => o.copies > 0).map(o => o.user)
  } else {
    selected = users.filter(o => _selectObjectIds.has(o.id))
  }

  const displayCount = selected.length > 5 ? 5 : selected.length
  const displayUsers = [...selected].slice(0, displayCount)
  let i = 0
  const images = displayUsers.map(u => {
    let imageUrl = u.imageURL
    if (!imageUrl) {
      imageUrl = userImageUrls[u.imageId]
    }

    i++
    return(
      <Image style={{
        position: 'relative',
        right: i === 1 ? '0px' : `${10 * (i - 1)}px`,
        border: '2px solid white',
        borderRadius: '50%',
        zIndex: i
      }}
        key={i}
        size='40px'
        imageUrl={imageUrl} />
    )
  })

  return (
    <>
      <label className={styles.label} onClick={handleClicked}>
        <div className={styles.name}>
          {images}
          <div style={{
            position: 'relative',
            right: `${(displayCount - 1) * 10 - 8}px`
          }}>
            <div className={styles.countSection}>
              <div className={[styles.count, valid ? '' : styles.invalid].join(' ')}>
                {selected.length}
              </div>
              <Svg
                className={valid ? null : 'invalid'}
                style={{
                  marginLeft: '4px',
                  fontSize: '15px'
                }}
                icon='person'
                size='24'/>
            </div>
          </div>
        </div>
        <div className={styles.icon}>
          選擇
        </div>
      </label>

      <Drawer
        open={drawerActive}
        closed={handleClosed}>
        {select}
      </Drawer>
    </>
  )
};

export default Label


