import React, { useContext, useEffect, useState } from "react"
import { Context as AuthContext } from '../contexts/AuthContext'
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { FullPageLoader } from '../components'
import liff from '@line/liff';
import NoticePage from '../pages/NoticePage'

import { useCurrentAccountingBook } from '../hooks'

const MagicRedirect = ({
  page
}) => {
  const { state: authState } = useContext(AuthContext)
  const [groupId, setGroupId] = useState(null);

  useEffect(() => {
    if (authState.lineContext !== null) {
      let context = authState.lineContext
      let identifier = null

      if (context.type == 'group') {
        identifier = context.groupId
      }

      if (context.type == 'room') {
        identifier = context.roomId
      }

      if (context.type == 'utou') {
        identifier = context.userId
      }

      console.log(context.type)
      authState.api.getGroupByLineId(identifier)
        .then((res) => {
          setGroupId(res.data.id)
        })
        .catch((err) => {
        });
    }
  }, [authState.lineContext])


  if (groupId) {
    let redirectPath = `https://liff.line.me/${process.env.REACT_APP_LINE_LIFF_ID}/groups/${groupId}/current_payments`
    window.location = redirectPath
  }

  if (authState.lineContext && authState.lineContext.type == 'external') {
    return <NoticePage text="請在手機操作，謝謝！"/>
  } else {
    return <FullPageLoader />
  }
}

export default MagicRedirect
