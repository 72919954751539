import React, { useContext } from "react"
import styles from './FilterPaymentForm.module.scss'
import TextInput from '../../FormElements/TextInput/TextInput'
import { Context as FilterContext } from '../../../contexts/FilterPaymentContext'
import { useDefaultUsersSelect  } from '../../../hooks'
import { UserLabel, AccountingBookUserMenu  } from '../../../components'
import {
  createFilterUserCheckbokLabel
} from '../../../generators/labelGenerator'

const FilterPaymentForm = ({
  changed,
  users,
  selectedUsers,
  onClosedClick,
  callback
}) => {
  const buildSelectUsers = (users) => {
    return selectedUsers.map((u) => u.id)
  }

  const filterDisabled = (users) => {
    return []
  }

  const handleClosed = () => {
    onClosedClick(value)
  }

  const titleComp = <>
    <div className={styles.header}>
      <div className={styles.headerLabel}>
        篩選帳款
      </div>
      <div className={styles.checkboxClose} onClick={handleClosed}>
        完成
      </div>
    </div>
  </>
  const [value, select] = useDefaultUsersSelect({
    users,
    buildSelectUsers,
    createLabel: createFilterUserCheckbokLabel,
    filterDisabled,
    handleAddUser: () => {  },
    selectAll: true,
    handleEdit: () => {},
    handleTrash: () => {},
    labelsHeight: "calc(100%) - 61px - 44px",
    callback: callback,
    titleComponent:titleComp
  })

  return(
    <div className={styles.container}>
      <div className={styles.labels}>
        {select}
      </div>
    </div>
  )
}

export default FilterPaymentForm
