import React, { useState, useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views';
import Views from './Views/Views'

const ColumnSwappableView = ({
  styles,
  index,
  steps,
  callback,
  height,
  showView
}) => {
  const [_index, setIndex] = useState(index)

  let _showView = true
  if (showView != undefined) {
    _showView = false
  }

  const handleIndexChanged = (value) => {
    setIndex(value)
    if (callback) { callback(value) }
  }

  useEffect(() => {
    if (index != undefined) {
      setIndex(index)
    }
  }, [index])


  let root_style = {
    flexGrow: 1,
    maxHeight: '-webkit-fill-available',
    width: '100%',
    margin: 0,
    overflowY: 'auto',
    overflowX: 'hidden'
  }

  if (_showView == true) {
    root_style.height = height ? `calc(${height} - 51px)` : 'calc(100%  - 51px)'
    root_style.overflowY = 'hidden'
    root_style.overflowX = 'hidden'
  }


  const defaultStyles = {
    root: root_style,
    slideContainer: {
      height: `calc(100%)`,
      margin: 0,
      overflow: 'hidden',
      backgroundColor: '#FFFFFF',
    },
    slide: {
      overflow: 'hidden',
      height: `calc(100%)`,
      backgroundColor: '#FFFFFF',
    },
  };

  const _styles = styles ? { ...defaultStyles, ...styles } : defaultStyles
  const stepsComponents = []

  steps.forEach(step => {
    stepsComponents.push(
      <div key={step.name} style={Object.assign({}, _styles.slide)}>
        {step.component}
      </div>
    )
  })

  return (
    <>
      {
        _showView ?
          <Views steps={steps} index={parseInt(_index)} setIndex={handleIndexChanged}/> : null
      }
      <SwipeableViews style={_styles.root}
        disabled={true}
        index={parseInt(_index)}
        slideStyle={_styles.slideContainer}>
        {stepsComponents}
      </SwipeableViews>
    </>
  )
}

export default ColumnSwappableView
