import React, { useContext } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import NavigationPage from './pages/NavigationPage'
import SorryPage from './pages/SorryPage'
import HomePage from './pages/HomePage/HomePage'
import GroupIdRedirect from './pages/GroupIdRedirect'
import { CookiesProvider } from 'react-cookie';
import { useLiff } from './hooks'
import { Helmet } from "react-helmet";

const App = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  return (
    <>
      <Helmet>
        <meta name="google-adsense-account" content="ca-pub-1659910690978575"/>
      </Helmet>
      <BrowserRouter>
        <Switch>
          <Route exact path="/s/:group_id">
            <GroupIdRedirect/>
          </Route>
          <Route exact path="/sorry">
            <SorryPage />
          </Route>
          <Route exact path="/">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous" />
            <HomePage></HomePage>
          </Route>
          <CookiesProvider>
            <NavigationPage />
          </CookiesProvider>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
