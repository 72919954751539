import React, { useState, useEffect } from 'react';
import FormatString from '../../../utilities/FormatString'
import { useCookies } from 'react-cookie';
import styles from './paymentCheckboxLabel.module.scss'
import { useParams } from 'react-router-dom';
import Checkbox from  '../Inputs/Checkbox'
import { Image } from '../../index'
import { Collapse } from '../../index'
import {
  userImageUrls
} from '../../../constants'

import {
  useHistory
} from '../../../hooks'
import {
  Svg,
  Separater,
} from '../../../components'

const PaymentCheckboxLabel = (props) => {
  const [ collapseOpen , setCollapseOpen ] = useState(false)
  const { navigateTo, navigate } = useHistory();
  /* eslint-disable no-unused-vars */
  const [_, setCookie] = useCookies([]);
  const { group_id, accounting_book_id } = useParams();
  const open = collapseOpen && !props.editMode
  const [ isChecked, setIsChecked ] = useState(props.checked)

  useEffect(() => {
    if (props.selectedPaymentIds) {
      setIsChecked(props.selectedPaymentIds.includes(object.id.toString()))
      /* eslint-disable react-hooks/exhaustive-deps */
    }
  }, [props.selectedPaymentIds])

  let object = props.object
  let activeClass = open ? styles.active : ''
  let amount = object.amount

  const handleLabelOnCheck = (e) => {
    if (e.target.checked) {
      setCollapseOpen(true)
    } else {
      setCollapseOpen(false)
    }
  }

  let i = 0
  const allocations = object.allocations.map(allo => {
    i += 1
    return(
      <div key={i} className={[styles.allocation].join(' ')}>
        <div className={styles.allocationInner}>
          <span className={[styles.alloName].join(' ')}> { allo.ower_display_name } </span> <span className={[styles.alloAmount].join(' ')}>{props.currency_symbol} {allo.amount}</span>
        </div>
      </div>
    )
  })

  const buildPaymentPayers = () => {
    let payment_payers = []

    if (object.payment_payers) {
      let i = 0
      payment_payers = object.payment_payers.map(pp => {
        i += 1
        return(
          <div key={`p-${i}`} className={[styles.allocation].join(' ')}>
            <div className={[styles.allocationInner, styles.green700, styles.thick].join(' ')}>
              <span className={[styles.alloName].join(' ')}> { pp.user_display_name } </span> <span className={[styles.alloAmount].join(' ')}>{props.currency_symbol} {pp.amount}</span>
            </div>
          </div>
        )
      })
    }
    return payment_payers
  }

  const buildPaymentPayer = (display_name, amount) => {
    return (
      <div key={`p-${i}`} className={[styles.allocation].join(' ')}>
        <div className={[styles.allocationInner, styles.green700, styles.thick].join(' ')}>
          <span className={[styles.alloName].join(' ')}> { display_name } </span> <span className={[styles.alloAmount].join(' ')}>{props.currency_symbol} {amount}</span>
        </div>
      </div>
    )
  }

  let payment_payers = buildPaymentPayers()

  const handleDeleteClick = () => {
    props.deleted(object)
  }

  const handleEditClick = () => {
    navigate(`/liff_entry/groups/${group_id}/accounting_books/${accounting_book_id}/payments/${object.id}/edit`)
  }

  const handlePaidBackEditClick = () => {
    let payment = {
      id: object.id,
      description: object.description,
      amount: object.amount,
      paid_back: true,
      allocation_type: 'amount',
      payer_id:  object.payer_id,
      ower_id: object.allocations[0].ower_id
    }

    setCookie('payment', payment, { path: '/' });
    navigateTo('paidBackPaymentPage', { group_id, accounting_book_id, payment })
  }

  const handleCheckboxChaned = (e) => {
    setIsChecked(e.target.checked)
    props.changed(e.target.checked, e.target.value)
  }

  let paidBack = ""
  if (object.paid_back) { paidBack = styles.paidBack }

  let selected = object.payment_payers
  let displayUsers = 0
  let displayCount = 1
  let multiPayerCount = 1
  let images = []
  if (selected) {
    multiPayerCount = selected.length
    displayCount = selected.length > 3 ? 3 : selected.length
    displayUsers = [...selected].slice(0, displayCount)
    let idx = 0
    images = displayUsers.map(u => {
      let imageUrl = u.user_image_url
      if (!imageUrl) {
        imageUrl = userImageUrls[u.imageId]
      }

      idx++
      return(
        <Image style={{
          position: 'relative',
          left: idx === 1 ? '0px' : `${-10 * (idx - 1)}px`,
          // border: '2px solid white',
          borderRadius: '50%',
          zIndex: 30 - idx
        }}
          key={idx}
          size='24px'
          imageUrl={imageUrl} />
      )
    })
  }


  let extraPadding = 6
  if (displayCount === 2) {
    extraPadding = 6
  } else if (displayCount === 3){
    extraPadding = 20
  }

  let payerCount = 1
  if (object.payment_payers) {
    payerCount = object.payment_payers.length
  }

  return (
    <>
      <div className={[styles.container, activeClass, paidBack].join(' ')}>
        <label className={[`${activeClass} ${paidBack}`, styles.label].join(" ")}>
          {
            props.editMode ?
              <Checkbox style={{"paddingRight": "5px"}} checked={isChecked} value={object.id.toString()} changed={handleCheckboxChaned}/>:
              <div className={[styles.checkbox].join(" ")}>
                <input
                  onChange={handleLabelOnCheck}
                  type="checkbox"
                  value={ object.id }
                />
              </div>
          }
          <div className={[styles.paymentBlock].join(' ')}>
            <div className={[styles.name].join(" ")}>
              <div className={[styles.description].join(" ")}>
                {
                  object.paid_back ?
                    FormatString.sliceToLength(`還 ${object.allocations[0].ower_display_name}`, 18, '..')
                    :
                    FormatString.sliceToLength(object.description, 18, '..')
                }
              </div>
              <div className={[styles.message].join(" ")}>
                {props.currency_symbol} { amount }
              </div>
            </div>
            <div className={[styles.amount].join(" ")}>
              <div className={[styles.amountLeft].join(" ")}>
                {
                  object.multi_payer ?
                    <>
                      <div className={styles.multiImage}>
                        {images}
                        <div
                          className={styles.countBox}
                          style={{
                            position: 'relative',
                            right: `${(displayCount - 1) * 40}px`,
                            right: `16px`,
                            bottom: '-5px',
                            display: "none"
                          }}>
                          <div style={{display: "none"}}className={styles.countSection}>
                            {
                              selected.length > 1 ?
                                <div className={[styles.count].join(' ')}>
                                  {selected.length}
                                </div> : null
                            }
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className={[styles.image].join(' ')}>
                        <Image imageUrl={object.payer_image_url} defaultImage='user' size='24px'/>
                      </div>
                    </>
                }
                <div className={[styles.description].join(" ")} style={{paddingLeft: `${extraPadding}px`}}>
                  {
                    object.paid_back ?
                      <p className={[styles.paidBack].join(" ")}> {object.payer_display_name} 還款 </p>
                      :
                      <p>
                        { multiPayerCount == 1 ?  `${object.payer_display_name} 付款` : `${multiPayerCount}人付款` }
                      </p>
                  }
                </div>
              </div>
              <div className={[styles.message].join(" ")}>
                {
                  payerCount > 1 ?
                    `${object.allocations.length}人分`
                    :
                    `${object.allocations.length}人分`
                }
              </div>
            </div>
          </div>
        </label>
      </div>
      <Collapse isOpened={open}
        initialStyle={open ? { height: 'auto', overflow: 'initial' } : { height: '0px', overflow: 'hidden' }} >
        <div className={[`${paidBack}`, styles.paymentCollapse].join(" ")}>
          {
            object.paid_back ?
              null
              :
                <div className={[styles.allocations].join(" ")}>
                  {
                    object.multi_payer ?
                      <>
                        <div className={[styles.allocationSection].join(' ')}  style={{ paddingTop: "4px" }}>
                          <div className={[styles.allocation].join(' ')}>付款</div>
                        </div>
                        {payment_payers}
                        <Separater style={{ margin: "4px" }}/>
                      </>
                      :
                      <>
                        <div className={[styles.allocationSection].join(' ')}  style={{ paddingTop: "4px" }}>
                          <div className={[styles.allocation].join(' ')}>付款</div>
                        </div>
                        {buildPaymentPayer(object.payer_display_name, object.amount)}
                        <Separater style={{ margin: "4px" }}/>
                      </>
                  }
                  <div className={[styles.allocationSection].join(' ')}>
                    <div className={[styles.allocation].join(' ')}>分款</div>
                  </div>
                  {allocations}
                </div>
          }
          {
            props.lock_status == "locked" && !object.paid_back ?
              null :
              <div className={[styles.buttons, paidBack].join(" ")}>
                <div onClick={handleDeleteClick} className={[styles.btn].join(" ")}>
                  刪除
                </div>
                {
                  object.paid_back ? null :
                    <div onClick={handleEditClick} className={[styles.btn, styles.edit].join(' ')}>
                      編輯
                    </div>
                }
              </div>
          }
        </div>
      </Collapse>
    </>
  )
};

export default PaymentCheckboxLabel;
