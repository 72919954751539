import React, { Component, Fragment, lazy, Suspense } from 'react';
import styles from './Index.module.scss';

// const Donate = lazy(() => import('../Donate/Donate'));
const IntroBlock = lazy(() => import('./IntroBlock/IntroBlock'));
const QuestionUsageBlock = lazy(() => import('./questionUsageBlock/questionUsageBlock'));
const UsageSteps = lazy(() => import('./usageSteps/usageSteps'));
const UsageCondition = lazy(() => import('./usageCondition/usageCondition'));
const Features = lazy(() => import('./features/features'));
const HomeMenuIntro = lazy(() => import('./homeMenuIntro/homeMenuIntro'));
const Members = lazy(() => import('./members/members'));
const AddBabyDragon = lazy(() => import('./addBabyDragon/addBabyDragon'));
const CopyRight = lazy(() => import('./copyRight/copyRight'));

class Index extends Component {
  render() {
    return(
      <Fragment>
        <Suspense fallback={ <div></div> }>
          <div className={styles.container}>
            <IntroBlock></IntroBlock>
            <QuestionUsageBlock></QuestionUsageBlock>
            <UsageSteps></UsageSteps>
            <UsageCondition></UsageCondition>
            <Features></Features>
            <HomeMenuIntro></HomeMenuIntro>
            <Members></Members>
            <AddBabyDragon></AddBabyDragon>
            <CopyRight></CopyRight>
          </div>
        </Suspense>

      </Fragment>
    )
  }
};

export default Index;


