import React from "react"
import { themeColors } from '../constants'
import { EmptyResult } from '../components'

const NoticePage = ({ text }) => {
  return <div style={styles.body}>
    <div style={styles.container}>
      <div style={{ padding: '4px' }}>
        <EmptyResult message={text}/>
      </div>
    </div>
  </div>
}

const styles = {
  body: {
    width: '100%',
    height: '100vh',
    backgroundColor: themeColors.green700,
  },
  container: {
    fontFamily: 'Trebuchet MS',
    position: 'absolute',
    top: '45%',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    width: 'calc(100%)',
    borderRadius: '16px',
    textAlign: 'center',
    color: themeColors.green700,
  }
}

export default NoticePage
