import React, { useState, useEffect, useContext } from 'react';
import liff from '@line/liff';
import styles from './PaymentForm.module.scss'
import { split_into } from '../../../utilities/Calculator';
import { themeColors } from '../../../constants'
import { useParams } from 'react-router-dom';
import { Context as PaymentContext } from '../../../contexts/PaymentContext'
import { evaluate } from 'mathjs'
import { sumOwers, sumPaymentObjects } from '../../../utilities/PaymentFormHelper'
import {
  FullPageLoader,
  Section,
  DatePickerInput,
  Svg,
  Button,
  TextInput,
  OwerEditableCheckboxSelectLabel,
  PayerEditableCheckboxSelectLabel,
} from '../../../components'
import {
  useHistory,
  useUserRadioSelectLabel,
} from '../../../hooks'

const PaymentForm = ({ users, manualOwers, index, owers, payment, authState, customPayers }) => {
  const debug = false
  const [disableForm, setDisableForm] = useState(true)
  const [loading, setLoading] = useState(false)
  const [fixedAmount, setFixedAmount] = useState(null)
  const { group_id, accounting_book_id } = useParams()
  const history = useHistory();
  const {
    state,
    validateForm,
    createPayment,
    resetForm
  } = useContext(PaymentContext)

  /* eslint-disable no-unused-vars */
  const [_name, _setName] = useState({ value: '', valid: true })
  const [_allocationType, _setAllocationType] = useState(0) // 0 - by acount, 1 - by piece
  const [_payerAllocationType, _setPayerAllocationType] = useState(0) // 0 - by acount, 1 - by piece
  const [formTouched, setFormTouched] = useState(false)
  const [_amount, _setAmount] = useState({ value: '', valid: true })
  const [_creation_date, _setCreationDate] = useState({ value: null, valid: true })
  const [_owers, _setOwers] = useState({ value: [], valid: true })
  const [_manualOwers, _setManualOwers] = useState({ value: [], valid: true })
  const [_payers, _setPayers] = useState({ value: [], valid: true })
  const [_customPayers, _setCustomPayers] = useState({ value: [], valid: true })
  const [summaryAmount, setSummaryAmount] = useState(null)
  const [payerSummaryAmount, setPayerSummaryAmount] = useState(null)
  /* eslint-disable no-unused-vars */
  const [_payer, _setPayer] = useState({ value: null, valid: true })
  const [alertMessage, setAlertMessage] = useState(null)
  const [_users, setUsers] = useState([])
  const [summary, setSummary] = useState({
    valid: true,
    message: '總額與分帳小計不符，請調整'
  })

  let use_multi_payer = false
  let locked = false
  if (state.accounting_book_details) {
    use_multi_payer = state.accounting_book_details.use_multi_payer
    locked = state.accounting_book_details.lock_status == "locked"
  }

  useEffect(() => {
    if (users.length !== _users.length) {
      setUsers(users)
      setDisableForm(false)
    }
  }, [users])

  useEffect(() => {
    // edit existing payment prefill
    if (payment) {
      setDisableForm(true)
      _setName({ value: payment.description, valid: true })
      _setAmount({ value: payment.amount, valid: true })
      setFixedAmount(payment.amount)
      setSummaryAmount(payment.amount)
      setPayerSummaryAmount(payment.amount)
      _setCreationDate({ value: payment.paid_at, valid: true })
      setDisableForm(false)
    }
  }, [payment, _setName, _setAmount, _setCreationDate, customPayers])

  useEffect(()=> {
    if (_allocationType === 1) {
      setCopiesSummary(_manualOwers)
    }
  }, [_manualOwers, _allocationType])

  useEffect(()=> {
    if (use_multi_payer && _payerAllocationType === 1) {
      setCopiesSummary(_customPayers)
    }
  }, [_customPayers, _payerAllocationType])


  const isValidCopies = (objects) => {
    let sum = 0
    let summaryValid = true

    objects.value.forEach(o => {
      if (o.copies !== undefined) {
        sum += o.copies
      }
    })
    if (sum === 0) {
      summaryValid = false
    }

    return summaryValid
  }

  const setCopiesSummary = (m_owers) => {
    setSummary({
      message: '請輸入份數',
      valid: isValidCopies(m_owers)
    })
  }

  useEffect(() => {
    if (!formTouched) {
      return
    }

    if (_allocationType === 0 || _payerAllocationType === 0) {
      var summaryValid = true

      if (fixedAmount != undefined && fixedAmount > 0) {
        if (use_multi_payer) {
          summaryValid = parseFloat(fixedAmount) === parseFloat(summaryAmount) && parseFloat(fixedAmount) === parseFloat(payerSummaryAmount)
        } else {
          summaryValid = parseFloat(fixedAmount) === parseFloat(summaryAmount)
        }
      } else {
        summaryValid = false
      }

      setSummary({
        message: '總額與分帳小計不符，請調整',
        valid: summaryValid
      })
    }
  }, [formTouched, fixedAmount, payerSummaryAmount, summaryAmount, _allocationType, _payerAllocationType])

  const getSummaryValid = (alloType, sumAmount, objects) => {
    let valid = true
    if (alloType === 0) {
      if (fixedAmount && fixedAmount > 0) {
        valid = parseFloat(fixedAmount) === parseFloat(sumAmount)
      }
    } else {
      valid = isValidCopies(objects)
    }
    return valid
  }

  let payerSumValid = getSummaryValid(_payerAllocationType, payerSummaryAmount, _customPayers)
  let alloSumValid = getSummaryValid(_allocationType, summaryAmount, _manualOwers)
  let summaryValid = payerSumValid && alloSumValid

  const [payer, payerLabel] = useUserRadioSelectLabel({
    users: _users,
    initialValue: state.payer.value,
  })

  const nameInput = <TextInput
    style={{ paddingTop: '16px' }}
    key='name'
    svg={<Svg icon='Payment' size='24' className='gold900'/> }
    disabled={false}
    placeholder='輸入款項'
    name={'款項'}
    changed={(v) => _setName({ value: v, valid: v.length > 0 })}
    value={_name.value}
    valid={_name.valid}
    invalidFeedback="內容不可為空，12字內"
    type='text'
  />

  const datePickerInput = <DatePickerInput
    name='日期'
    changed={(v) => _setCreationDate({ value: v, valid: true })}
    value={_creation_date.value}
    svg={<Svg icon='Date' size='24' className='gold900'/> }
    />

  const handleAmountChanged = (v) => {
    setFormTouched(true)
    _setAmount({ value: v, valid: true, touched: true })

    if (v.length === 0) {
      setFixedAmount(null)
    } else {
      setFixedAmount(v)
    }
    triggerPayersReallocation(v)
    triggerOwersReallocation(v)
  }

  const triggerPayersReallocation = (amount) => {
    let touched = false
    _customPayers.value.forEach(o => {
      if (o.touched) {
        touched = true
      }
    })

    if (!touched) {
      _setCustomPayers(
        getCustomPayersAmount(
          users, _payers.value, _customPayers.value.map(o => o.user.id), _customPayers, amount
        ).state,
      )
      setPayerSummaryAmount(amount)
    } else {
      let validIds = _customPayers.value.filter(o => o.amount > 0).map(o => o.user.id)
      _setPayers({ value: users.filter(u => validIds.includes(u.id)), valid: true })
    }
  }

  const triggerOwersReallocation = (amount) => {
    let touched = false

    _manualOwers.value.forEach(o => {
      if (o.touched) {
        touched = true
      }
    })

    if (!touched) {
      // _setManualOwers(getManualOwersAmount(users, _owers.value, _manualOwers.value.filter(o => !o.user.touched).map(o => o.user.id), v).state)
      _setManualOwers(
        getManualOwersAmount(
          users, _owers.value, _manualOwers.value.map(o => o.user.id), _manualOwers, amount
        ).state,
      )
      setSummaryAmount(amount)
    } else {
      let validManualOwersIds = _manualOwers.value.filter(o => o.amount > 0).map(o => o.user.id)
      _setOwers({ value: users.filter(u => validManualOwersIds.includes(u.id)), valid: true })
    }
  }

  const handleOwersChanged = (objs) => {
    let currentOwers = _owers.value
    _setOwers({ value: objs, valid: true, touched: true })

    setFormTouched(true)
    if (objs.length === 0 && !fixedAmount) {
      _setAmount({ value: 0, valid: true })
    }
  }

  const handlePayersChanged = (objs) => {
    let currentPayers = _payers.value
    _setPayers({ value: objs, valid: true, touched: true })

    setFormTouched(true)
    if (objs.length === 0 && !fixedAmount) {
      _setAmount({ value: 0, valid: true })
    }
  }

  const getCustomPayersAmount = (users, owers, unTouchedIds, valueObjects, totalAmount) => {
    let selectedOwerIds = owers.map(o => o.id)
    let copies_objects = valueObjects.value.filter(el => el.copies > 0)
    let copies_objects_ids = copies_objects.map(o => o.user.id)

    let amount_array = split_into(totalAmount, selectedOwerIds.length, state.accounting_book_details.exponent)

    let i = 0
    let m_owers = users.map(u => {
      let amount;

      if (!totalAmount || totalAmount === 0) {
        amount = ''
      } else if (selectedOwerIds.includes(u.id)) {
        amount = amount_array[i]
        i++
      } else {
        amount = ''
      }

      let copies = 0
      if (copies_objects_ids.includes(u.id)) {
        copies = copies_objects.filter(o => (o.user.id === u.id))[0].copies
      }

      return {
        user: u,
        amount: amount,
        copies: copies
      }
    })

    return({
      state: {
        value: m_owers,
        valid: true
      },
      splits: amount_array
    }
    )
  }

  const getManualOwersAmount = (users, selectedObjs, unTouchedIds, customObjects, totalAmount) => {
    let selectedIds = selectedObjs.map(o => o.id)
    let copies_objects = customObjects.value.filter(el => el.copies > 0)
    let copies_objects_ids = copies_objects.map(o => o.user.id)

    let amount_array = split_into(totalAmount, selectedIds.length, state.accounting_book_details.exponent)

    let i = 0
    let c_objs = users.map(u => {
      let amount;

      if (!totalAmount || totalAmount === 0) {
        amount = ''
      } else if (selectedIds.includes(u.id)) {
        amount = amount_array[i]
        i++
      } else {
        amount = ''
      }

      let copies = 0
      if (copies_objects_ids.includes(u.id)) {
        copies = copies_objects.filter(o => (o.user.id === u.id))[0].copies
      }

      return {
        user: u,
        amount: amount,
        copies: copies
      }
    })

    return({
      state: {
        value: c_objs,
        valid: true
      },
      splits: amount_array
    }
    )
  }

  const handleSetPayerAmount = (object_id, value) => {
    if (_payerAllocationType != 0) {
      return
    }

    let newObjects = [..._customPayers.value]
    let index = newObjects.findIndex(o => o.user.id === object_id)

    newObjects[index] = {
      user: newObjects[index].user,
      amount: value,
      touched: true
    }

    if (!fixedAmount) {
      let sum = sumPaymentObjects(newObjects, state.accounting_book_details.exponent)
      _setAmount({ value: sum, valid: sum > 0 })
      triggerOwersReallocation(sum)
    }
  }


  const handleSetOwerAmount = (object_id, value) => {
    if (_allocationType != 0) {
      return
    }

    let newOwers = [..._manualOwers.value]
    let index = newOwers.findIndex(o => o.user.id === object_id)

    newOwers[index] = {
      user: newOwers[index].user,
      amount: value,
      touched: true
    }

    if (!fixedAmount) {
      let sum = sumOwers(newOwers, state.accounting_book_details.exponent)
      _setAmount({ value: sum, valid: sum > 0 })
      triggerPayersReallocation(sum)
    }
  }

  const amountInput = <TextInput
    key='amount'
    svg={<Svg icon='Money' size='24' className='gold900'/> }
    disabled={false}
    placeholder='輸入金額'
    name='金額'
    style={{ borderRadius: '16px' }}
    changed={handleAmountChanged}
    value={_amount.value}
    valid={summary.valid && _amount.valid}
    invalidFeedback={!summary.valid ?  "" : "內容不可為空，小於100000000"}
    type='number'
  />

  const handleAmountLabelsOpen = () => {
  }

  const handleDrawerClosed = () => {
    if (_amount.value > 0) {
      setFixedAmount(_amount.value)
    }
  }

  const payersLabel = <PayerEditableCheckboxSelectLabel
    exponent={state.accounting_book_details ? state.accounting_book_details.exponent : 0}
    setSummaryAmount={setPayerSummaryAmount}
    getManualOwersAmount={getCustomPayersAmount}
    setOwerAmount={handleSetPayerAmount}
    fixedAmount={fixedAmount}
    allocationType={_payerAllocationType}
    setAllocationType={_setPayerAllocationType}
    users={_users ? _users.filter(u => u.coverCost === true) : []}
    manualOwers={_customPayers}
    selectChanged={handlePayersChanged}
    setManualOwers={_setCustomPayers}
    valid={payerSumValid}
    selectedObjects={_payers.value}
    handleOpen={handleAmountLabelsOpen}
    handleDrawerClosed={handleDrawerClosed}
  />

  const owersLabel = <OwerEditableCheckboxSelectLabel
    exponent={state.accounting_book_details ? state.accounting_book_details.exponent : 0}
    setSummaryAmount={setSummaryAmount}
    getManualOwersAmount={getManualOwersAmount}
    setOwerAmount={handleSetOwerAmount}
    fixedAmount={fixedAmount}
    allocationType={_allocationType}
    setAllocationType={_setAllocationType}
    users={_users ? _users.filter(u => u.coverCost === true) : []}
    manualOwers={_manualOwers}
    selectChanged={handleOwersChanged}
    setManualOwers={_setManualOwers}
    valid={alloSumValid}
    selectedObjects={_owers.value}
    handleOpen={handleAmountLabelsOpen}
    handleDrawerClosed={handleDrawerClosed}
  />

  const handleManualOwersChanged = (index, data) => {
    let newOwers = [..._manualOwers.value]
    newOwers[index] = data

    let objects = newOwers.filter(object => object.amount > 0)
    let amount = objects.reduce((prev, object) => {
      if (parseFloat(object.amount) > 0) { return prev + parseFloat(object.amount) }
      else { return (prev + 0) }
    }, 0)

    let valid = newOwers.filter(ower => ower.amount > 0).length === newOwers.length

    _setManualOwers({ value: newOwers, valid: valid })
  }

  useEffect(() => {
    if (_owers.value.length === 0) {
      _setOwers({ value: owers, valid: true })
      _setManualOwers({ value: manualOwers, valid: true })
    }
  }, [owers])

  useEffect(() => {
    if (payment) {
      let payers = customPayers.filter(o => o.amount > 0).map(o => o.user)
      _setPayers({ value: payers, valid: true })
      _setCustomPayers({ value: customPayers, valid: true })
    } else {
      if (_payers.value.length === 0) {
        _setPayers({ value: [state.payer.value], valid: true })
        _setCustomPayers({ value: customPayers, valid: true })
      }
    }
  }, [customPayers, payment])

  const form = {
    evenly: ['name', 'amount', 'payer', 'owers', 'creation_date'],
    amount: ['name', 'payer', 'amount', 'manualOwers', 'creation_date'],
    multiPayerAmount: ['name', 'customPayers', 'amount', 'manualOwers', 'creation_date'],
  }

  const buildPaymentSuccessMessage = (message, log_message_category, accounting_book_name) => {
    let contents = {
      "type": "bubble",
      "size": "mega",
      "body": {
        "type": "box",
        "layout": "vertical",
        "contents": [
          {
            "type": "box",
            "layout": "baseline",
            "contents": [
              {
                "type": "text",
                "text": `${log_message_category} 至`,
                "color": "#A58341",
                "align": "start",
                "size": "xs",
                "gravity": "center",
                "weight": "bold",
                "style": "normal",
                "position": "relative",
                "decoration": "none",
                "wrap": true,
                "flex": 0
              },
              {
                "type": "text",
                "text": accounting_book_name,
                "align": "start",
                "size": "xs",
                "gravity": "center",
                "weight": "bold",
                "style": "normal",
                "position": "relative",
                "decoration": "none",
                "wrap": true,
                "flex": 0,
                "margin": "md"
              }
            ]
          },
          {
            "type": "box",
            "layout": "vertical",
            "contents": [
              {
                "type": "text",
                "text": message,
                "size": "sm",
                "wrap": true
              }
            ],
            "paddingTop": "xs"
          }
        ],
        "backgroundColor": "#ffffff",
        "paddingAll": "xl"
      },
      "styles": {
        "header": {
          "separator": true
        },
        "footer": {
          "separator": true
        }
      }
    }

    let flex_message = {
      "type": "flex",
      "altText": '新增款項',
      "contents": contents
    }

    return flex_message
  }


  const getManualOwers = () => {
    if (_allocationType === 0) {
      return _manualOwers
    }

    if (_allocationType === 1) {
      return getManualOwersBasedOnCopies(_manualOwers)
    }
  }

  const getCustomPayers = () => {
    if (_payerAllocationType === 0) {
      return _customPayers
    }
    if (_payerAllocationType === 1) {
      return getManualOwersBasedOnCopies(_customPayers)
    }
  }

  const getManualOwersBasedOnCopies = (baseObjects) => {
    if (!_amount.value) {
      return baseObjects
    }

    let sum = baseObjects.value.reduce((accumulator, object) => {
      if (object.copies != undefined) {
        return accumulator + object.copies;
      } else {
        return accumulator
      }
    }, 0);

    let ary = split_into(_amount.value, sum, state.accounting_book_details.exponent)

    let newObjects = baseObjects.value.map(o => {
      if (o.copies > 0) {
        let new_ary = ary.splice(0, o.copies)
        let amount = new_ary.reduce((acc, obj) => {
          return acc + obj
        })

        o.amount = amount

      } else {
        o.amount = 0
      }

      return o
    })

    let new_sum = newObjects.reduce((accumulator, object) => {
      if (object.amount != undefined) {
        return accumulator + object.amount;
      } else {
        return accumulator
      }
    }, 0);

    return { value: newObjects, valid: new_sum > 0 }
  }

  const resetAll = () => {
    _setAllocationType(0)
    _setPayerAllocationType(0)
    resetForm()
  }
  const redirectToSettingPage = () => {
    history.navigateTo("accountingBookSettingsPage", { group_id, accounting_book_id })
  }

  const handleSubmit = () => {
    let newState = {
      ...state,
      manualOwers: getManualOwers(),
      customPayers: getCustomPayers(),
      owers: _owers,
      payer: { value: payer, valid: true },
      name: _name,
      creation_date: _creation_date,
      amount: _amount,
      allocation_type: 'amount',
      use_multi_payer: use_multi_payer
    }

    let valid = false
    if (use_multi_payer) {
      valid = validateForm(newState, form['multiPayerAmount'], validate)
    } else {
      valid = validateForm(newState, form['amount'], validate)
    }
    if (!valid) {
      console.log(newState)
      console.log('[Debug] payment invalid')
      return
    }

    setDisableForm(true)
    setLoading(true)

    createPayment(authState.api, newState, (data) => {
      if (data.status === "bad_request") {
        resetAll()
        alert(data.message)
      } else {
        if (data.send_liff_confirm_message === true) {
          if (liff.isInClient()) {
            let message = buildPaymentSuccessMessage(data.log_message_content, data.log_message_category, data.accounting_book_name)
            let messages = [message]
            liff.sendMessages(messages)
              .then(() => {})
          }
        }
        resetAll()
        history.navigateTo("paymentIndexPage", { group_id, accounting_book_id })
      }
    })
  }

  const setFunction = {
    'name': _setName,
    'manualOwers': _setManualOwers,
    'amount': _setAmount,
    'owers': _setOwers
  }

  const validate = (newState) => {
    if (!newState) { return }

    ['name', 'amount'].forEach(el => {
      if (newState[el] && !newState[el].valid) {
        setFunction[el]({ value: newState[el].value, valid: false })
      }
    })
  }

  let disabled = true
  if (!summary.valid) {
    disabled = true
  } else {
    disabled = disableForm
  }

  let payersSummary = null
  if (use_multi_payer && !loading) {
    if (debug) {
      console.log(`payerSummaryAmount: ${payerSummaryAmount}`)
      console.log(`fixedAmount: ${fixedAmount}`)
      console.log(`payerSumValid: ${payerSumValid}`)
    }
    if (_allocationType == 0 && state.accounting_book_details && payerSummaryAmount > 0) {
      payersSummary = <div style={{ color: payerSumValid ? themeColors.green700 : themeColors.invalid, fontFamily: 'Noto Sans TC' }}>
        { `小計： ${state.accounting_book_details.currency_symbol}${payerSummaryAmount}`
        }
      </div>
    }

    if (_payerAllocationType === 1) {
      payersSummary = <div style={{ color: payerSumValid ? themeColors.green700 : themeColors.invalid, fontFamily: 'Noto Sans TC' }}>
        以份數分配
      </div>
    }
  }

  let allocationSummary = null
  if (!loading) {
    if (_allocationType == 0 && state.accounting_book_details && summaryAmount > 0) {
      allocationSummary = <div style={{ color: alloSumValid ? themeColors.green700 : themeColors.invalid, fontFamily: 'Noto Sans TC' }}>
        {
          `小計： ${state.accounting_book_details.currency_symbol}${summaryAmount}`
        }
      </div>
    }

    if (_allocationType === 1) {
      allocationSummary = <div style={{ color: alloSumValid ? themeColors.green700 : themeColors.invalid, fontFamily: 'Noto Sans TC' }}>
        以份數分配
      </div>
    }
  }

  return(
    <>
      <div className={styles.container}>
        <div className={styles.stepContainer}>
          { nameInput }
          { alertMessage }
          { amountInput }
          { datePickerInput }
          {
            use_multi_payer ?
              <>
                <Section name="付款者" style={{ marginTop: '16px' }}>
                  {
                    payersSummary
                  }
                </Section>
                { payersLabel }
              </> :
              <>
                <Section name="付款者"/>
                { payerLabel }
              </>
          }
          <Section name="分款者" style={{ marginTop: '16px' }}>
            {
              allocationSummary
            }
          </Section>
          { owersLabel }
        </div>

        {

          locked ?
            <div className={styles.footer}>
              <Button color="green" clicked={redirectToSettingPage} disabled={(disabled || loading)}>前往解鎖帳本</Button>
              <div style={{
                color: themeColors.invalid,
                fontSize: '12px',
                textAlign: 'center',
                paddingTop: '6px',
                opacity:  summary.valid ? '0%' : '100%'
              }}>
                {summary.message}
              </div>
            </div> :
            <div className={styles.footer}>
              <Button clicked={handleSubmit} disabled={(disabled || loading)}>建立帳款</Button>
              <div style={{
                color: themeColors.invalid,
                fontSize: '12px',
                textAlign: 'center',
                paddingTop: '6px',
                opacity:  summary.valid ? '0%' : '100%'
              }}>
                {summary.message}
              </div>
            </div>
        }

      </div>
      {
        (disableForm || loading) ? <FullPageLoader /> : null
      }
    </>
  )
}

  const defaultStyles = {
    root: {
      flexGrow: 1,
      maxHeight: '-webkit-fill-available',
      margin: 0,
      height: 'calc(100% - 49px - 84px)',
      overflow: 'hidden',
    },
    slideContainer: {
      height: 'calc(100%)',
      margin: 0,
      overflow: 'hidden',
    },
    slide: {
      overflow: 'auto',
      height: 'calc(100%)',
      backgroundColor: '#FFFFFF',
    },
  };
export default PaymentForm
