import { useHistory } from "react-router-dom";

const Hook =  () => {
  const history = useHistory();

  const navigate = (url) => {
    history.push(url)
  }

  const redirectTo = (url) => {
    window.location = url
  }

  const toIndex = () => {
    redirectTo("https://dragon-baby.herokuapp.com/")
  }

  const navigateTo = (page, args) => {
    if (args.group_id !== undefined) {
      navigate(routes[page](args))
    }
  }

  const routes = {
    sorry: () => "/sorry",
    accountingBookPage: (args) => `/liff_entry/groups/${args.group_id}/accounting_books`,
    accountingBookCreationPage: (args) => `/liff_entry/groups/${args.group_id}/accounting_books/new`,
    accountingBookCurrencyPage: (args) => `/liff_entry/groups/${args.group_id}/accounting_books/${args.accounting_book_id}/currency`,
    accountingBookSettingsPage: (args) => `/liff_entry/groups/${args.group_id}/accounting_books/${args.accounting_book_id}/settings`,
    accountingBookEditPage: (args) => `/liff_entry/groups/${args.group_id}/accounting_books/${args.accounting_book_id}/edit/index`,
    accountingBookDefaultUserPage: (args) => `/liff_entry/groups/${args.group_id}/accounting_books/${args.accounting_book_id}/default_users`,
    paymentIndexPage: (args) => `/liff_entry/groups/${args.group_id}/accounting_books/${args.accounting_book_id}/payments/index`,
    paidBackPaymentPage: (args) => `/liff_entry/groups/${args.group_id}/accounting_books/${args.accounting_book_id}/payments/paid_back?`
  }

  return { navigate, navigateTo, routes, toIndex, redirectTo };
}

export default Hook;
